import keycloak from "@/helpers/auth";
import { useAuthStore } from "@/stores/useAuthStore";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "Home",
      authRequired: false,
    },
    component: () => import("../views/landing/index"),
  },

  {
    path: "/products",
    name: "products",
    meta: {
      title: "pageTitles.products",
      authRequired: false,
    },
    component: () => import("../views/machine-front/index.vue"),
  },
  {
    path: "/products/:internalno",
    name: "MachineDetails",
    meta: {
      title: "pageTitles.details",
      authRequired: false,
    },
    component: () => import("../views/machine-front/MachineDetails.vue"),
  },

  {
    path: "/imprint",
    name: "imprint",
    meta: {
      title: "pageTitles.imprint",
      authRequired: false,
    },
    component: () => import("../views/others/imprint.vue"),
  },

  {
    path: "/privacy",
    name: "privacy",
    meta: {
      title: "pageTitles.privacy",
      authRequired: false,
    },
    component: () => import("../views/others/privacy.vue"),
  },

  {
    path: "/cookies",
    name: "cookies",
    meta: {
      title: "pageTitles.cookies",
      authRequired: false,
    },
    component: () => import("../views/others/cookies.vue"),
  },

  {
    path: "/about",
    name: "about",
    meta: {
      title: "pageTitles.about",
      authRequired: false,
    },
    component: () => import("../views/others/about.vue"),
  },

  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "pageTitles.contact",
      authRequired: false,
    },
    component: () => import("../views/others/contact.vue"),
  },

  {
    path: "/faq",
    name: "faq",
    meta: {
      title: "FAQ",
      authRequired: false,
    },
    component: () => import("../views/others/faq.vue"),
  },
  /*
  {
    path: "/company",
    name: "company",
    meta: {
      title: "Company",
      authRequired: false,
    },
    component: () => import("../views/machine-front/Company.vue"),
  },
  {
    path: "/company-edit",
    name: "company-edit",
    meta: {
      title: "Company Edit",
      authRequired: true,
    },
    component: () => import("../views/machine-back/CompanyEdit.vue"),
  },
  */
  {
    path: "/log",
    name: "import-log",
    meta: {
      title: "Import Log",
      authRequired: true,
    },
    component: () => import("../views/machine-back/MachineLog.vue"),
  },
  // Neue Route für Maschinen-Details hinzufügen

  {
    path: "/search",
    name: "SearchResults",
    meta: {
      title: "pageTitles.search",
      authRequired: false,
    },
    component: () => import("../views/search/SearchResults.vue"), // oder wo du die Komponente ablegst
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
    },
    beforeEnter() {
      const authStore = useAuthStore();
      authStore.clearToken(); // Token löschen
      // Keycloak-Logout mit Redirect-URI
      keycloak.logout({ redirectUri: window.location.origin });
      // Falls keycloak.logout() den Redirect nicht übernimmt, kann next("/") genutzt werden:
      // next("/");
    },
  },
];

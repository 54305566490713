<template>
  <CustomSpinner />
  <router-view></router-view>
  <CookieBanner />
</template>

<script>
import CustomSpinner from "@/components/modules/CustomSpinner.vue";
import { useHead } from "@vueuse/head";
import appConfig from "../app.config";
import CookieBanner from "@/components/modules/CookieBanner.vue";
import { watch } from "vue";
import { useRoute } from "vue-router";
import i18n from "@/i18n";

export default {
  name: "App",
  components: {
    CustomSpinner,
    CookieBanner,
  },

  setup() {
    const route = useRoute();
    watch(
      () => i18n.global.locale, // oder i18n.global.locale.value
      () => {
        if (route.meta && route.meta.title) {
          const translatedTitle = i18n.global.t(route.meta.title);
          document.title = translatedTitle + " | " + appConfig.title;
        }
      }
    );
    useHead({
      meta: [
        {
          key: "default-description",
          name: "description",
          content: appConfig.description,
        },
        {
          key: "default-keywords",
          name: "keywords",
          content: appConfig.keywords,
        },
      ],
    });
  },
};
</script>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { createHead } from "@vueuse/head";

import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import BootstrapVueNext from "bootstrap-vue-next";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska";
import VueFeather from "vue-feather";

import "@/assets/scss/config/material/app.scss";
import "@/assets/scss/mermaid.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import { createPinia } from "pinia";
// Wichtig: Wir brauchen für das Watchen auch den `watch`-Helper aus Vue
import { watch } from "vue";

import { theme } from "@/layouts/theme.js";
import { usePreloaderStore } from "@/stores/usePreloaderStore";
// --- Unser Auth-Store, damit wir Token & Status abfragen können
import { useAuthStore } from "@/stores/useAuthStore";
// --- Deine Keycloak-Instanz
import keycloak from "@/helpers/auth";

import "quill/dist/quill.snow.css";

if ("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}

const pinia = createPinia();

// 1) Head-Instanz erzeugen
const head = createHead();

const app = createApp(App);
app.use(head);
app.use(store);
app.use(pinia);
app.use(VueApexCharts);
app.use(BootstrapVueNext);
app.component(VueFeather.type, VueFeather);
app.directive("maska", vMaska);
app.use(i18n);
app.use(VueSweetalert2);
app.use(vClickOutside);
app.config.globalProperties.$theme = theme;

// Preloader deaktivieren nach Start
const preloaderStore = usePreloaderStore();
preloaderStore.setLoading(false);

app.use(router).mount("#app");

// --------------------------------------------------------------
// Ab hier: Automatisches Token-Refresh nach erfolgreichem Login
// --------------------------------------------------------------

// 1) Auth-Store Instanz holen
const authStore = useAuthStore();

// 2) Intervall-Referenz
let refreshInterval = null;

// 3) Start-/Stop-Methoden:
function startRefresh() {
  if (refreshInterval) {
    // Schon gestartet?
    return;
  }
  // Alle 60s versuchen wir, das Token zu aktualisieren
  refreshInterval = setInterval(() => {
    // Token nur updaten, wenn Keycloak init war
    keycloak
      .updateToken(30) // minValidity = 30s
      .then((refreshed) => {
        if (refreshed) {
          console.log("Token erneuert.");
          // Setze das neue Token + Refresh Token in deinem Store ab
          authStore.setToken(keycloak.token, keycloak.refreshToken);
        } else {
          // Token ist noch gültig genug, kein Refresh nötig
        }
      })
      .catch((error) => {
        console.error("Fehler beim Token-Refresh:", error);
        // Falls gewünscht: Intervall stoppen oder zum Login umleiten
        stopRefresh();
      });
  }, 60000); // 60s
}

function stopRefresh() {
  if (refreshInterval) {
    clearInterval(refreshInterval);
    refreshInterval = null;
  }
}

// 4) Watch auf den Auth-Status
//    Sobald "authStore.getAuthStatus" -> true wechselt, starten wir den Timer
//    Wenn "authStore.getAuthStatus" -> false, stoppen wir den Timer
watch(
  () => authStore.getAuthStatus,
  (isAuthenticated) => {
    if (isAuthenticated) {
      console.log("User ist authentifiziert -> Starte Token-Refresh");
      startRefresh();
    } else {
      console.log(
        "User ist nicht (mehr) authentifiziert -> Stoppe Token-Refresh"
      );
      stopRefresh();
    }
  },
  { immediate: true }
);

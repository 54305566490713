import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import keycloak from "@/helpers/auth";
import { useAuthStore } from "@/stores/useAuthStore";
import { usePreloaderStore } from "@/stores/usePreloaderStore";
import appConfig from "../../app.config";
import { useAuthUserStore } from "@/stores/useAuthUserStore";
import i18n from "@/i18n";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 200); // 100ms Delay, nach Bedarf anpassen
    });
  },
});

// Authentifizierung prüfen
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const preloaderStore = usePreloaderStore();
  const authUserStore = useAuthUserStore();

  // Seitentitel aktualisieren
  document.title = to.meta.title + " | " + appConfig.title;

  if (to.meta && to.meta.title) {
    const translatedTitle = i18n.global.t(to.meta.title);
    document.title = translatedTitle + " | " + appConfig.title;
  }

  // Authentifizierung erforderlich
  if (to.meta.authRequired) {
    if (!authStore.getAuthStatus) {
      // Benutzer ist nicht authentifiziert
      try {
        console.log(
          "Benutzer nicht authentifiziert. Initialisiere Keycloak..."
        );
        await keycloak.init({
          onLoad: "login-required",
          redirectUri: window.location.origin + to.fullPath, // Redirect nach Login
          checkLoginIframe: false,
        });

        // Token und Benutzerprofil speichern
        authStore.setToken(keycloak.token, keycloak.refreshToken);

        const profile = await keycloak.loadUserProfile();
        authStore.setEmail(profile.email);
        authUserStore.setUser(
          profile.email,
          profile.firstName,
          profile.lastName
        );

        console.log("Benutzer erfolgreich authentifiziert. Weiterleitung...");
        preloaderStore.setLoading(false);
        //next(to.fullPath); // Zur ursprünglichen Zielroute weiterleiten
        next(); // Navigation fortsetzen
        router.replace({ path: to.path, query: to.query });
        return;
      } catch (error) {
        console.error("Keycloak-Fehler:", error);
        preloaderStore.setLoading(false);
        next("/"); // Bei Fehler zur Startseite weiterleiten
      }
    } else {
      // Benutzer ist authentifiziert
      console.log("Benutzer authentifiziert. Navigation fortsetzen...");
      preloaderStore.setLoading(false);
      next(); // Navigation fortsetzen
    }
  } else {
    // Keine Authentifizierung erforderlich
    console.log("Öffentliche Route. Navigation fortsetzen...");
    preloaderStore.setLoading(false);
    next();
  }
});

export default router;

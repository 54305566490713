<template>
  <div v-if="showBanner" class="cookie-banner">
    <div class="cookie-banner-content">
      <p>
        {{ $t("t-cookies-text") }}
      </p>
      <div class="cookie-banner-buttons">
        <button type="button" class="btn btn-primary btn-sm" @click="acceptAll">
          {{ $t("t-cookies-accept") }}
        </button>
        <button type="button" class="btn btn-primary btn-sm" @click="rejectAll">
          {{ $t("t-cookies-reject") }}
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="goToSettings"
        >
          {{ $t("t-cookies-settings") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  data() {
    return {
      showBanner: true,
      // Zustimmungsstatus für die einzelnen Dienste
      consent: {
        youtube: false,
        googleTagManager: false,
        salesViewer: false,
      },
    };
  },
  mounted() {
    const savedConsent = localStorage.getItem("cookieConsent");
    if (savedConsent) {
      this.consent = JSON.parse(savedConsent);
      this.showBanner = false;
      // Falls bereits zugestimmt wurde, werden ggf. die Skripte geladen
      if (this.consent.googleTagManager) {
        this.loadGoogleTagManager();
      }
      if (this.consent.salesViewer) {
        this.loadSalesViewer();
      }
    }
  },
  methods: {
    acceptAll() {
      // Alle Dienste freischalten
      for (const key in this.consent) {
        this.consent[key] = true;
      }
      this.saveConsentAndClose();
    },
    rejectAll() {
      // Alle Dienste sperren
      for (const key in this.consent) {
        this.consent[key] = false;
      }
      this.saveConsentAndClose();
    },
    saveConsentAndClose() {
      // Consent in localStorage speichern
      localStorage.setItem("cookieConsent", JSON.stringify(this.consent));
      this.showBanner = false;
      // Dynamisch Skripte laden, falls Zustimmung vorliegt
      if (this.consent.googleTagManager) {
        this.loadGoogleTagManager();
      }
      if (this.consent.salesViewer) {
        this.loadSalesViewer();
      }
      // Globales Event dispatchen, damit z.B. der YouTube-Placeholder aktualisiert wird
      window.dispatchEvent(
        new CustomEvent("cookieConsentChanged", { detail: this.consent })
      );
      // Falls andere Komponenten über den Eventbus reagieren sollen
      this.$emit("consent-changed", this.consent);
    },
    loadGoogleTagManager() {
      if (!document.getElementById("gtm-script")) {
        const script = document.createElement("script");
        script.id = "gtm-script";
        script.async = true;
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W6D23VS6');`;
        document.head.appendChild(script);
      }
      if (!document.getElementById("gtm-noscript")) {
        const noscript = document.createElement("noscript");
        noscript.id = "gtm-noscript";
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W6D23VS6" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.insertBefore(noscript, document.body.firstChild);
      }
    },
    loadSalesViewer() {
      if (!document.getElementById("salesviewer-script")) {
        const script = document.createElement("script");
        script.id = "salesviewer-script";
        script.async = true;
        script.innerHTML = `!(function (s, a, l, e, sv, i, ew, er) {try {(a = s[a] || s[l] || function () {throw 'no_xhr';}),(sv = i = 'https://salesviewer.org'),(ew = function(x){(s = new Image()), (s.src = 'https://salesviewer.org/tle.gif?sva=J9m0D4G0h0C6&u='+encodeURIComponent(window.location)+'&e=' + encodeURIComponent(x))}),(l = s.SV_XHR = function (d) {return ((er = new a()),(er.onerror = function () {if (sv != i) return ew('load_err'); (sv = 'https://www.salesviewer.com/t'), setTimeout(l.bind(null, d), 0);}),(er.onload = function () {(s.execScript || s.eval).call(er, er.responseText);}),er.open('POST', sv, !0),(er.withCredentials = true),er.send(d),er);}),l('h_json=' + 1 * ('JSON' in s && void 0 !== JSON.parse) + '&h_wc=1&h_event=' + 1 * ('addEventListener' in s) + '&sva=' + e);} catch (x) {ew(x)}})(window, 'XDomainRequest', 'XMLHttpRequest', 'J9m0D4G0h0C6');`;
        document.body.appendChild(script);
      }
      if (!document.getElementById("salesviewer-noscript")) {
        const noscript = document.createElement("noscript");
        noscript.id = "salesviewer-noscript";
        noscript.innerHTML = `<img src='https://salesviewer.org/J9m0D4G0h0C6.gif' style='visibility:hidden;' />`;
        document.body.appendChild(noscript);
      }
    },
    goToSettings() {
      // Navigiere zur /cookies Seite
      this.$router.push("/cookies");
    },
  },
};
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f9f9f9;
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.cookie-banner-content {
  max-width: 1000px;
  width: 100%;
}
.cookie-banner-content p {
  margin: 0 0 1rem 0;
}
.cookie-banner-buttons {
  display: flex;
  gap: 1rem;
}
</style>
